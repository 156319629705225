import React from 'react'
import { graphql } from 'gatsby'
import ClientSearch from '../components/ClientSearch'
import Layout from '../components/layout'
import get from 'lodash/get'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'

const SearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  const heroImage = get(props, 'data.heroImage')
  const site = get(props, 'data.site')   
  return (
    <Layout>
      <div id="sub-page">
      <SEO 
          title="UNICOM Engineering search" 
          description="Struggling to find a particular web page or piece of content? Use the UNICOM Engineering search to hone in on the information that you need."
          url={site.siteMetadata.siteUrl + props.location.pathname} 
          image={site.siteMetadata.siteUrl + heroImage.childImageSharp.fluid.src}
        />
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.fluid}
          title="Search UNICOM Engineering"
          subtitle=""
          type="search"
        />
      </div>

      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <ClientSearch content={allData} engine={options} context="site" />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default SearchTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    heroImage: file(name: { eq: "search" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
